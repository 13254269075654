















































































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import { Appointment, Client } from "@/types";
import appointmentStoreModule from "@/store/modules/appointment";
import moment from "moment-timezone";

const { mapActions: appointmentActions, mapGetters: appointmentGetters } =
  createNamespacedHelpers("APPOINTMENT_LIST");

export default Vue.extend<any, any, any, any>({
  name: "AppointmentList",
  props: {
    client: {
      type: Object as PropType<Client>,
    },
    status: {
      type: String,
    },
    paymentStatus: {
      type: String,
    },
    filterType: {
      type: String,
    },
    employeeId: {
      type: String,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    showClient: {
      default: false,
    },
  },
  data: () => ({
    appointment: undefined,
    showDialog: false,
    showNotesDialog: false,
    editingNotes: false,
    notes: "",

    options: {} as { page: number },
  }),
  created() {
    this.fetchAppointments();
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.fetchAppointments();
      },
      deep: true,
    },
    status: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
    paymentStatus: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
    filterType: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
    employeeId: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
  },
  computed: {
    ...appointmentGetters(["appointmentPage"]),
    headers() {
      if (this.showClient) {
        return [
          { text: "Client", value: "client" },
          {
            text: "Service",
            align: "start",
            sortable: true,
            value: "services",
          },
          { text: "Date", value: "date" },
          { text: "Time", value: "time" },

          { text: "Appointment Status", value: "status" },
          { text: "Payment Status", value: "paymentStatus" },
          { text: "Action", value: "action" },
        ];
      }
      return [
        {
          text: "Service",
          align: "start",
          sortable: true,
          value: "services",
        },
        { text: "Date", value: "date" },
        { text: "Time", value: "time" },
        { text: "Staff", value: "employee" },
        { text: "Appointment Status", value: "status" },
        { text: "Payment Status", value: "paymentStatus" },
        { text: "Action", value: "action" },
      ];
    },
  },
  methods: {
    ...appointmentActions([
      "fetchAppointmentList",
      "updateAppointment",
      "deleteAppointment",
    ]),

    getFirstAndLastDate(duration) {
      const currentDate = new Date();
      let firstDay = new Date(currentDate);
      let lastDay = new Date(currentDate);
      const dayOfWeek = currentDate.getDay();

      switch (duration.toLowerCase()) {
        case "month":
          // First day of the current month
          firstDay.setDate(1);
          // Last day is the first day of the next month
          lastDay.setMonth(currentDate.getMonth() + 1);
          lastDay.setDate(1);
          break;

        case "week":
          firstDay.setDate(currentDate.getDate() - dayOfWeek);
          lastDay.setDate(firstDay.getDate() + 6);
          break;
        default:
          // First and last day are the same for "day" and default cases
          firstDay = lastDay;
          break;
      }

      return {
        firstDay: firstDay.toISOString(),
        lastDay: lastDay.toISOString(),
      };
    },
    getLastDate(duration) {
      const currentDate = new Date();
      let endDate = new Date(currentDate);

      switch (duration.toLowerCase()) {
        case "month":
          endDate.setMonth(endDate.getMonth() + 1);
          break;
        case "week":
          endDate.setDate(endDate.getDate() + 7);
          break;
        case "category":
          endDate.setDate(endDate.getDate() + 1);
          break;
        default:
          endDate.setDate(endDate.getDate() + 1);
      }

      return endDate.toISOString();
    },
    saveNotes(id: string) {
      this.updateAppointment({ id, appointment: { notes: this.notes } });
    },
    fetchAppointments() {
      let params = `?clientId=${
        this.client?._id
      }&businessId=${localStorage.getItem("businessId")}&page=${
        this.options.page || 1
      }`;

      if (!this.client) {
        params = `?page=${
          this.options.page || 1
        }&businessId=${localStorage.getItem("businessId")}`;
      }

      if (this.options.itemsPerPage && this.options.itemsPerPage > 1) {
        params = `${params}&limit=${this.options.itemsPerPage}`;
      }

      if (this.status && this.status.toLowerCase() != "all appointments") {
        params += params.length
          ? `&status=${this.status.toLowerCase()}`
          : `?status=${this.status.toLowerCase()}`;
      }

      if (
        this.paymentStatus &&
        this.paymentStatus.toLowerCase() != "all payments"
      ) {
        params += params.length
          ? `&paymentStatus=${this.paymentStatus.toLowerCase()}`
          : `?paymentStatus=${this.paymentStatus.toLowerCase()}`;
      }

      if (this.startDate) {
        // this.startDate.hours(23).minutes(59).seconds(59);
        // this.endDate.hours(23).minutes(59).seconds(59);
        params += params.length
          ? `&startDate=${this.startDate.toISOString()}`
          : `?startDate=${this.startDate.toISOString()}`;
      }

      if (this.endDate) {
        // this.startDate.hours(23).minutes(59).seconds(59);
        // this.endDate.hours(23).minutes(59).seconds(59);
        params += params.length
          ? `&endDate=${this.endDate.toISOString()}`
          : `?endDate=${this.endDate.toISOString()}`;
      }

      // if (this.filterType) {
      //   const { firstDay, lastDay } = this.getFirstAndLastDate(
      //     this.filterType.toLowerCase()
      //   );
      //   params += params.length
      //     ? `&endDate=${lastDay}&startDate=${firstDay}`
      //     : `?endDate=${lastDay}&startDate=${firstDay}`;
      // }
      if (this.employeeId) {
        params += params.length
          ? `&employeeId=${this.employeeId.toLowerCase()}`
          : `?employeeId=${this.employeeId.toLowerCase()}`;
      }

      if (this.options && this.options?.sortBy?.length) {
        var prefix = params.length ? "&" : "?";
        var sortPrefix = this.options.sortDesc[0] ? "-" : "";
        let field = this.options.sortBy[0];
        if (field == "date" || field == "time") {
          field = "startDate";
        }
        params += `${prefix}sort=${sortPrefix}${field}`;
      }
      console.log(this.options);

      this.fetchAppointmentList(params);
    },
    getEndDate(appointment: Appointment) {
      return moment(appointment.startDate)
        .add(appointment.durationInMinutes, "minutes")
        .toISOString();
    },
  },
  beforeCreate() {
    if (this.$store.hasModule("APPOINTMENT_LIST")) {
      this.$store.unregisterModule("APPOINTMENT_LIST");
    }
    this.$store.registerModule("APPOINTMENT_LIST", appointmentStoreModule);
  },
});
